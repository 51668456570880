import { FC, useState, useEffect } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { Chart } from "react-google-charts";
import { checkCount } from '../../stats/statsCount';
import '../../stats/activity/Activity.scss';
import '../PdfReport.scss';

const PageFive: FC = () => {
    const period = useTypedSelector(state => state.period.period);
    const activityDataResult = useTypedSelector(state => state.statsActivity.dataResult);
    const loading = useTypedSelector(state => state.statsActivity.loading);
    const error = useTypedSelector(state => state.statsActivity.error);

    const [chartData, setChartData] = useState<any[]>([]);

    const testChartData = [
        [
            "Genre",
            "Blocks",
            "Cybersecurity blocks",
            "Allowed requests",
            {
                "role": "annotation"
            }
        ],
        [
            "00:00",
            80962,
            11766,
            968160,
            ""
        ],
        [
            "01:00",
            69826,
            12365,
            749410,
            ""
        ],
        [
            "02:00",
            48232,
            10862,
            622827,
            ""
        ],
        [
            "03:00",
            37465,
            10836,
            522750,
            ""
        ],
        [
            "04:00",
            35910,
            10799,
            520073,
            ""
        ],
        [
            "05:00",
            35252,
            10995,
            504124,
            ""
        ],
        [
            "06:00",
            137168,
            10879,
            645132,
            ""
        ],
        [
            "07:00",
            49149,
            10903,
            840140,
            ""
        ],
        [
            "08:00",
            63373,
            14996,
            1443672,
            ""
        ],
        [
            "09:00",
            201593,
            35199,
            4552371,
            ""
        ],
        [
            "10:00",
            276096,
            51631,
            5684490,
            ""
        ],
        [
            "11:00",
            323717,
            51475,
            7431265,
            ""
        ],
        [
            "12:00",
            832521,
            46439,
            5934659,
            ""
        ],
        [
            "13:00",
            186918,
            31038,
            3367874,
            ""
        ],
        [
            "14:00",
            132799,
            19599,
            2108445,
            ""
        ],
        [
            "15:00",
            138167,
            14551,
            1530072,
            ""
        ],
        [
            "16:00",
            126253,
            13689,
            1219331,
            ""
        ],
        [
            "17:00",
            86931,
            13530,
            1182789,
            ""
        ],
        [
            "18:00",
            80125,
            13087,
            1216816,
            ""
        ],
        [
            "19:00",
            74242,
            13236,
            1189977,
            ""
        ],
        [
            "20:00",
            109924,
            13566,
            1271009,
            ""
        ],
        [
            "21:00",
            116586,
            12287,
            1277127,
            ""
        ],
        [
            "22:00",
            103609,
            11891,
            1192929,
            ""
        ],
        [
            "23:00",
            0,
            0,
            0,
            ""
        ]
    ];

    const sumRequests = {
        blocks: 0,
        dangerous_blocks: 0,
        requests: 0,
        timestamp: ""
    };

    const [requestsData, setRequestsData] = useState(sumRequests);

    const sumDataRequests = (data: any[]) => {
        for (let key in data[0]) {
            //@ts-ignore
            sumRequests[key] = 0;
        };

        data.reduce((acc: any, item: any) => {
            for (let key in item) {
                acc[key] += item[key];
            }
            return acc;
        }, sumRequests);
    };

    useEffect(() => {
        //sum requests

        sumDataRequests(activityDataResult)
        setRequestsData({
            ...requestsData,
            requests: sumRequests.requests,
            blocks: sumRequests.blocks,
            dangerous_blocks: sumRequests.dangerous_blocks
        });

        //chart data create

        let activityChart = [
            ['Genre', 'Всего заблокированных запросов', 'Всего предотвращено угроз', 'Разрешенные запросы']
        ];

        activityDataResult.map((activity: any) => {
            activityChart.push([
                // period === 'Today' || period === 'Yesterday' ? activity.timestamp.split(' ')[1].replace(/,|.{3}$/g, '') : activity.timestamp, TODO timestamp replace bug
                period === 'Today' || period === 'Yesterday' ? activity.timestamp && activity.timestamp.split(' ')[1] : activity.timestamp,
                activity.blocks,
                activity.dangerous_blocks,
                activity.requests - activity.blocks,
            ]);
        });

        {error || activityDataResult.length === 0 ?
            setChartData(testChartData) :
            setChartData(activityChart)
        }
    }, [activityDataResult, error]);

    const statsCount = (count: number, title: string, type: string) => {
        return (
            <div className={`stats-activity-chart-data-${type}`}>
                <p>{checkCount(count)}</p>
                <span>{title}</span>
            </div>
        )
    };

    //chart data
    const options = {
        isStacked: true,
        bar: { groupWidth: '65%' },
        legend: {
            position: "none",
        },
        with: 768,
        height: 350,
        colors: ['#FFB700', '#E08D8D', '#219653'],
        backgroundColor: '#FFF',
        fontSize: 11,
        focusTarget: 'category',
        tooltip: {
            isHtml: true,
            textStyle: {
                color: '#333',
                fontSize: 14
            }
        }
    };

    return (
        <div className="stats-activity-report">
            <h2 className="stats-activity-report-title">Активность</h2>

            <div className="stats-activity-report-data">
                <div className="stats-activity-chart">
                    <div className="stats-activity-chart-data">
                        {statsCount(loading ? 0 : requestsData.requests, 'Общее количество неотфильтрованных запросов', 'all')}
                        {statsCount(loading ? 0 : requestsData.blocks - requestsData.dangerous_blocks, 'Общее количество заблокированных запросов', 'blocked')}
                        {statsCount(loading ? 0 : requestsData.dangerous_blocks, 'Общее количество предотвращенных угроз', 'cybersecurity')}
                        {statsCount(loading ? 0 : requestsData.requests - requestsData.blocks, 'Разрешенные запросы', 'allowed')}
                    </div>
                    <Chart
                        chartType="ColumnChart"
                        width="100%"
                        data={chartData}
                        options={options}
                    />
                </div>
            </div>
            <p className="stats-activity-report-info-title">Общая информация об активности всех ваших пользователей за определенный период.</p>
        </div>
    )
};

export default PageFive;
