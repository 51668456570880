import React, { FC, useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsDomainsActionsTypes, IDomainItem } from "../../../types/statsDomains";
import { ICategotiesItemStats } from '../../../types/statsCategories';
import Endpoints from '../../../api/endpoints';
import usePagination from '../../../hooks/usePagination';
import useSortable from '../../../hooks/useSortable';
import DomainItem from './DomainItem';
import Search from '../../UI/search/Search';
import ContentBlock from '../../UI/content-block/ContentBlock';
import InfoBlockAccordion from '../../UI/info-block-accordion/InfoBlockAccordion';
import DomainsInfo from './DomainsInfo';
import Paginator from '../../UI/paginator/Paginator';
import Loader from '../../UI/loader/Loader';
import DropDownMenu from '../../UI/dropdown-menu/DropDownMenu';
import FetchError from '../../UI/fetch-error/FetchError';
import PickedFilter from '../../UI/picked-filter/PickedFilter';
import './Domains.scss';

const Domains: FC = () => {
    const dispatch = useDispatch();
    const { statsDomainsRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const categories = useTypedSelector(state => state.statsCategories.statsCategoriesData);

    const [domainsDataJson, setDomainsDataJson] = useState<string>("");
    const [domainsDataResult, setDomainsDataResult] = useState<IDomainItem[]>([]);
    const [filteredStatus, setFilteredStatus] = useState<string>("Все");
    const [filteredCategory, setFilteredCategory] = useState<{id: number, title: string}>({
        id: NaN,
        title: "Все"
    });
    const [searchDomains, setSearchDomains] = useState<string>("");
    const [searchCategory, setSearchCategory] = useState<string>("");
    const [isShowFilteringStatusMenu, setIsShowFilteringStatusMenu] = useState<boolean>(false);
    const [isShowFilteringCategoryMenu, setIsShowFilteringCategoryMenu] = useState<boolean>(false);

    const loading = useTypedSelector(state => state.statsDomains.loading);
    const error = useTypedSelector(state => state.statsDomains.error);

    const domainStatus = [
        {
            name: 'Все',
        },
        {
            name: 'Разрешенный',
        },
        {
            name: 'Смешанный',
        },
        {
            name: 'Заблокированный',
        }
    ];

    //get domains stats

    let recursionCount = 0;

    const getDomainsStats = () => {
        dispatch({
            type: StatsDomainsActionsTypes.GET_STATS_DOMAINS
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.DOMAINS}`, { ...requestData, order_by: "-visits" }, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setDomainsDataJson(response.data.message);

                return dispatch({
                    type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 10) {
                        return dispatch({
                            type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
                        });
                    } else {
                        return getDomainsStats();
                    }
                }, 3000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsDomainsActionsTypes.GET_STATS_DOMAINS_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getDomainsStats();
        }

        if (profiles.length !== 0) {
            statsDomainsRequest(getDomainsStats);
        }
    }, [requestData]);

    //get stats to json
    useEffect(() => {
        axios.get(domainsDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setDomainsDataResult(Array.from(response.data));
            dispatch({
                type: StatsDomainsActionsTypes.STATS_DOMAINS_DATA,
                payload: [...response.data].sort((a,b) => b.blocks - a.blocks).slice(0, 10)
            })

            dispatch({
                type: StatsDomainsActionsTypes.STATS_DOMAINS_DATA_RESULT,
                payload: response.data
            });
        })
        .catch((error) => {
            console.log('error');
        })
    }, [domainsDataJson]);

    //sorted domains to requests
    let { result, requestSort, sortConfig } = useSortable(domainsDataResult);

    const sortedDirectionClass = (name: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    //get category title list
    let catsList: any[] = categories.map((categoty) => {
        return {
            id: categoty.cat && categoty.cat.id,
            title: categoty.cat && categoty.cat.title
        }
    });

    //filtering domains
    if (searchDomains.length > 0) {
        result = result.filter(item => {
            return item.domain.toLocaleLowerCase().match(searchDomains)
        });
    };

    //filtering categories
    if (searchCategory.length > 0) {
        catsList = catsList.filter(item => {
            return item.title.toLocaleLowerCase().match(searchCategory)
        })
    };

    if (filteredCategory.title !== "Все") {
        result = result.filter(item => {
            return item.cat_ids.includes(filteredCategory.id)
        });
    }

    //filtering status
    if (filteredStatus === "Разрешенный") {
        result = result.filter(item => {
            return item.blocks === 0;
        })
    };

    if (filteredStatus === "Заблокированный") {
        result = result.filter(item => {
            return item.requests === item.blocks;
        })
    };

    if (filteredStatus === "Смешанный") {
        result = result.filter(item => {
            return item.requests !== item.blocks && item.blocks !== 0;
        })
    };

    const searchDomainsHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchDomains(e.target.value)
    };

    const searchCategoryHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchCategory(e.target.value)
    };

    const categoryFilteringHandler = (category: { id: number, title: string }): void => {
        setFilteredCategory(category);
        setIsShowFilteringCategoryMenu(!isShowFilteringCategoryMenu);
    };

    const statusFilteringHandler = (status: string): void => {
        setFilteredStatus(status);
        setIsShowFilteringStatusMenu(!isShowFilteringStatusMenu);
    };

    //map category ids
    const itemCategories = (data: ICategotiesItemStats[]) => {
        let cats: any[] = [];

        Array.from(data).map((cat_ids: any) => {
            return categories.filter((item: ICategotiesItemStats) => {
                if (item.cat !== undefined) {
                    if (item.cat.id === cat_ids) {
                        return cats.push(item.cat.title)
                    }
                }
            })
        });

        return cats.join(', ');
    };

    //pagination
    const itemsCount = 10;
    const {
        firstContentIndex,
        lastContentIndex,
        nextPage,
        prevPage,
        page,
        gaps,
        setPage,
        totalPages,
    } = usePagination({contentPerPage: itemsCount, count: result.length});

    return (
        <ContentBlock
            title={"Домены"}
            padding="0"
            isLink={true}
            linkUrl="/dashboard/new_statistics/detailed"
            linkName='Подробная статистика'
            content={
                <>
                    <div className="stats-domains">
                        <div
                            style={{
                                opacity: loading || error ? 0.2 : 1,
                                pointerEvents: loading || error ? 'none' : 'auto'
                            }}
                        >
                            <div className="stats-domains-controls">
                                <Search
                                    value={searchDomains}
                                    placeholder="Найти домен"
                                    margin="0"
                                    width="325px"
                                    searchHandler={(e: ChangeEvent<HTMLInputElement>) => searchDomainsHandler(e)}
                                />
                                <div className="stats-domains-controls-picked">
                                    {filteredCategory.title !== "Все" &&
                                        <PickedFilter
                                            title={"Категория"}
                                            picked={filteredCategory.title}
                                            clickHandler={()=> setFilteredCategory({
                                                id: NaN,
                                                title: "Все"
                                            })}
                                        />
                                    }

                                    {filteredStatus !== "Все" &&
                                        <PickedFilter
                                            // width="166"
                                            title={"Статус:"}
                                            picked={filteredStatus}
                                            clickHandler={()=> setFilteredStatus("Все")}
                                        />
                                    }
                                </div>
                            </div>

                            <ul className="stats-domains-list-header">
                                <li></li>
                                <li>
                                    <h5 className="stats-domains-list-header-title">ДОМЕНЫ</h5>
                                </li>
                                <li className="stats-domains-list-header-filter">
                                    <h5 className="stats-domains-list-header-title">КАТЕГОРИИ:</h5>
                                    <DropDownMenu
                                        name={filteredCategory.title.toLocaleUpperCase()}
                                        variant="outline"
                                        closeTrigger={isShowFilteringCategoryMenu}
                                        content={
                                            <>
                                                <Search
                                                    value={searchCategory}
                                                    placeholder="Search"
                                                    margin="0"
                                                    padding="16px"
                                                    width="180px"
                                                    searchHandler={(e: ChangeEvent<HTMLInputElement>) => searchCategoryHandler(e)}
                                                />

                                                <ul
                                                    className="dropdown-menu-list-item"
                                                    onClick={()=> categoryFilteringHandler({
                                                        id: NaN,
                                                        title: "Все"
                                                    })}
                                                    role="button"
                                                >
                                                    <li>Все</li>
                                                </ul>

                                                {catsList.map((item, index) => (
                                                    <ul
                                                        key={index}
                                                        className="dropdown-menu-list-item"
                                                        onClick={()=> categoryFilteringHandler(item)}
                                                        role="button"
                                                    >
                                                        <li>{item.title}</li>
                                                    </ul>
                                                ))}
                                            </>
                                        }
                                    />
                                </li>
                                <li
                                    className={`stats-domains-list-header-filter icon-sort ${sortedDirectionClass('requests')}`}
                                    onClick={() => {
                                        requestSort('requests');
                                    }}
                                >
                                    <h5 className="stats-domains-list-header-title">ЗАПРОСЫ</h5>
                                </li>
                                <li className="stats-domains-list-header-filter">
                                    <h5 className="stats-domains-list-header-title">СТАТУС:</h5>
                                    <DropDownMenu
                                        name={filteredStatus.toLocaleUpperCase()}
                                        variant="outline"
                                        closeTrigger={isShowFilteringStatusMenu}
                                        content={
                                            domainStatus.map((item, index) => (
                                                <ul
                                                    key={index}
                                                    className="dropdown-menu-list-item"
                                                    onClick={()=> statusFilteringHandler(item.name)}
                                                    role="button"
                                                >
                                                <li>{item.name}</li>
                                                </ul>
                                            ))
                                        }
                                    />
                                </li>
                            </ul>

                            {!error && domainsDataResult.length === 0 ?
                                <div className="stats-domains-list-empty">
                                    <p>У вас еще нет статистики</p>
                                </div> :
                                <div className="stats-domains-list">
                                    {result.length ? result
                                        .slice(firstContentIndex, lastContentIndex)
                                        .map((item, index) => (
                                            item.cat_ids &&
                                            <DomainItem
                                                key={index}
                                                domain={item.domain}
                                                category={item.cat_ids !== undefined && itemCategories(item.cat_ids)}
                                                requests={item.requests}
                                                blocks={item.blocks}
                                                sortChange={sortConfig}
                                                filteredCategoryChange={filteredCategory}
                                                filteredStatusChange={filteredStatus}
                                                paginationChange={firstContentIndex}
                                            />
                                        )) :
                                        <div className="stats-domains-list-empty">
                                            <p>По вашему запросу нет полезных результатов</p>
                                        </div>
                                    }

                                    {result.length > itemsCount &&
                                        <div className="stats-domains-list-pagination">
                                            <div className="stats-domains-list-pagination-counts">
                                                <span>Показаны</span>{`${firstContentIndex + 1}-${firstContentIndex + itemsCount}`}<span>из</span>{result.length}
                                            </div>
                                            <Paginator
                                                nextPage={nextPage}
                                                prevPage={prevPage}
                                                page={page}
                                                gaps={gaps}
                                                setPage={setPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                        </div>

                        {loading &&
                            <Loader
                                text='Пожалуйста, подождите...'
                            />
                        }

                        {error &&
                            <FetchError
                                text={error}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getDomainsStats}
                            />
                        }
                    </div>
                    <InfoBlockAccordion
                        id="domains"
                        title="Подробный отчет о 1000 доменах, к которым пользователи пытались получить доступ чаще всего."
                        padding="24px 24px 0"
                        is_content={true}
                        content={<DomainsInfo />}
                    />
                </>
            }
        />
    )
};

export default Domains;
