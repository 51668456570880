import { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsHighlightsActionsTypes } from "../../../types/statsHighlights";
import { checkCount } from '../statsCount';
import ContentBlock from '../../UI/content-block/ContentBlock';
import Endpoints from '../../../api/endpoints';
import HighlightsInfo from './HighlightsInfo';
import FetchError from '../../UI/fetch-error/FetchError';
import Loader from '../../UI/loader/Loader';
import './Highlights.scss';

const Highlights: FC = () => {
    const dispatch = useDispatch();
    const { statsHighlightsRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const [highlightsDataJson, setHighlightsDataJson] = useState<string>('');
    const [highlightsDataResult, setHighlightsDataResult] = useState<any>([]);

    const loading = useTypedSelector(state => state.statsHighlights.loading);
    const error = useTypedSelector(state => state.statsHighlights.error);

    //get highlights stats

    let recursionCount = 0;

    const getHighlightsStats = () => {
        dispatch({
            type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.HIGHLIGHTS}`, requestData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setHighlightsDataJson(response.data.message);

                return dispatch({
                    type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        return dispatch({
                            type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
                        });
                    } else {
                        return getHighlightsStats();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getHighlightsStats();
        }

        if (profiles.length !== 0) {
            statsHighlightsRequest(getHighlightsStats);
        }
    }, [requestData]);

    //get stats to json
    useEffect(() => {
        axios.get(highlightsDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setHighlightsDataResult(response.data);

            return dispatch({
                type: StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_DATA_RESULT,
                payload: response.data
            });
        })
        .catch(() => {
            console.log('error');
        })
    }, [highlightsDataJson]);

    const statsCount = (count: number, title: string, type: string) => {
        return (
            <>
                <span className={`stats-highlights-${type}-title`}>{title}</span>
                <p className={`stats-highlights-${type}-count`}>{checkCount(count)}</p>
            </>
        )
    };

    return (
        <ContentBlock
            title={"Основные показатели"}
            padding="0 24px"
            content={
                <>
                    <div style={{ position: 'relative' }}>
                        <div className="stats-highlights"
                            style={{
                                opacity: loading || error || highlightsDataResult.requests === 0 ? 0.2 : 1,
                                pointerEvents: loading || error || highlightsDataResult.requests === 0 ? 'none' : 'auto'
                            }}
                        >
                            <div className="stats-highlights-all">
                                {error || highlightsDataResult.requests === 0 ?
                                    <div>{statsCount(0, 'Все запросы', 'all')}</div> :
                                    <div>{statsCount(loading ? 0 : highlightsDataResult.requests, 'Все запросы', 'all')}</div>
                                }
                            </div>

                            <div className="stats-highlights-filtered">
                                {error || highlightsDataResult.requests === 0 ?
                                    <div>{statsCount(loading ? 0 : 0, 'Разрешенные запросы', 'filtered')}</div> :
                                    <div>{statsCount(loading ? 0 : highlightsDataResult.requests - highlightsDataResult.blocks, 'Разрешенные запросы', 'filtered')}</div>
                                }
                            </div>

                            <div className="stats-highlights-blocked">
                                {error || highlightsDataResult.requests === 0 ?
                                    <div>{statsCount(loading ? 0 : 0, 'Все заблокированные запросы', 'blocked')}</div> :
                                    <div>{statsCount(loading ? 0 : highlightsDataResult.blocks - highlightsDataResult.dangerous_blocks, 'Все заблокированные запросы', 'blocked')}</div>
                                }
                            </div>

                            <div className="stats-highlights-threats">
                                {error || highlightsDataResult.requests === 0 ?
                                    <div>{statsCount(loading ? 0 : 0, 'Предотвращенные угрозы', 'threats')}</div> :
                                    <div>{statsCount(loading ? 0 : highlightsDataResult.dangerous_blocks, 'Предотвращенные угрозы', 'threats')}</div>
                                }
                            </div>
                        </div>

                        {loading &&
                            <Loader
                                text='Пожалуйста, подождите...'
                            />
                        }

                        {error &&
                            <FetchError
                                text={error}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getHighlightsStats}
                            />
                        }

                        {!error && highlightsDataResult.requests === 0 &&
                            <FetchError
                                text={loading ? "" : "У вас еще нет статистики"}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getHighlightsStats}
                            />
                        }
                    </div>
                    <p className="stats-highlights-info-title">Общие сведения о потоке ваших запросов и общее количество конкретных типов запросов.</p>
                    <HighlightsInfo />
                </>
            }
        />
    )
};

export default Highlights;
