import React, { FC, useState, useEffect } from 'react';
import axios from 'axios';
import { useActions } from '../../../hooks/useActions';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { StatsActivityActionsTypes } from "../../../types/statsActivity";
import { Chart } from "react-google-charts";
import { checkCount } from '../statsCount';
import Endpoints from '../../../api/endpoints';
import UpdateStatsButton from '../updateStatsButton';
import ContentBlock from '../../UI/content-block/ContentBlock';
import InfoBlockAccordion from '../../UI/info-block-accordion/InfoBlockAccordion';
import Loader from '../../UI/loader/Loader';
import FetchError from '../../UI/fetch-error/FetchError';
import './Activity.scss';

const Activity: FC = () => {
    const dispatch = useDispatch();
    const { statsActivityRequest } = useActions();
    const { STATS } = Endpoints;

    const token = useTypedSelector(state => state.auth.token);
    const profiles = useTypedSelector(state => state.profiles.profiles);
    const period = useTypedSelector(state => state.period.period);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const [lastUpdate, setLastUpdate] = useState<string>('');
    const [activityDataResult, setActivityDataResult] = useState<any>([]);
    const [activityDataJson, setActivityDataJson] = useState<string>('');
    const [chartData, setChartData] = useState<any[]>([]);

    const loading = useTypedSelector(state => state.statsActivity.loading);
    const error = useTypedSelector(state => state.statsActivity.error);

    const formatter = new Intl.NumberFormat('en-US');

    const testChartData = [
        [
            "Genre",
            "Блокировки",
            "Блокировки кибербезопасности",
            "Разрешенные запросы",
            {
                "role": "annotation"
            }
        ],
        [
            "00:00",
            80962,
            11766,
            968160,
            ""
        ],
        [
            "01:00",
            69826,
            12365,
            749410,
            ""
        ],
        [
            "02:00",
            48232,
            10862,
            622827,
            ""
        ],
        [
            "03:00",
            37465,
            10836,
            522750,
            ""
        ],
        [
            "04:00",
            35910,
            10799,
            520073,
            ""
        ],
        [
            "05:00",
            35252,
            10995,
            504124,
            ""
        ],
        [
            "06:00",
            137168,
            10879,
            645132,
            ""
        ],
        [
            "07:00",
            49149,
            10903,
            840140,
            ""
        ],
        [
            "08:00",
            63373,
            14996,
            1443672,
            ""
        ],
        [
            "09:00",
            201593,
            35199,
            4552371,
            ""
        ],
        [
            "10:00",
            276096,
            51631,
            5684490,
            ""
        ],
        [
            "11:00",
            323717,
            51475,
            7431265,
            ""
        ],
        [
            "12:00",
            832521,
            46439,
            5934659,
            ""
        ],
        [
            "13:00",
            186918,
            31038,
            3367874,
            ""
        ],
        [
            "14:00",
            132799,
            19599,
            2108445,
            ""
        ],
        [
            "15:00",
            138167,
            14551,
            1530072,
            ""
        ],
        [
            "16:00",
            126253,
            13689,
            1219331,
            ""
        ],
        [
            "17:00",
            86931,
            13530,
            1182789,
            ""
        ],
        [
            "18:00",
            80125,
            13087,
            1216816,
            ""
        ],
        [
            "19:00",
            74242,
            13236,
            1189977,
            ""
        ],
        [
            "20:00",
            109924,
            13566,
            1271009,
            ""
        ],
        [
            "21:00",
            116586,
            12287,
            1277127,
            ""
        ],
        [
            "22:00",
            103609,
            11891,
            1192929,
            ""
        ],
        [
            "23:00",
            0,
            0,
            0,
            ""
        ]
    ];

    //get page activity

    let recursionCount = 0;

    const getActivityStats = () => {
        dispatch({
            type: StatsActivityActionsTypes.GET_STATS_ACTIVITY
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}${STATS.ACTIVITY}`, requestData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setActivityDataJson(response.data.message);
                setLastUpdate(response.data.updated);

                return dispatch({
                    type: StatsActivityActionsTypes.GET_STATS_ACTIVITY_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        return dispatch({
                            type: StatsActivityActionsTypes.GET_STATS_ACTIVITY_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
                        });
                    } else {
                        return getActivityStats();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsActivityActionsTypes.GET_STATS_ACTIVITY_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже.'
            });
        });
    };

    useEffect(() => {
        if (period !== 'Range' && requestData.profile_ids.length !== 0) {
            getActivityStats();
        }

        if (profiles.length !== 0) {
            statsActivityRequest(getActivityStats);
        }
    }, [requestData]);

    useEffect(() => {
        axios.get(activityDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setActivityDataResult(Array.from(response.data));

            return dispatch({
                type: StatsActivityActionsTypes.STATS_ACTIVITY_DATA_RESULT,
                payload: Array.from(response.data)
            });
        })
        .catch(() => {
            console.log('error');
        })
    }, [activityDataJson]);

    const sumRequests = {
        blocks: 0,
        dangerous_blocks: 0,
        requests: 0,
        timestamp: ""
    };

    const [requestsData, setRequestsData] = useState(sumRequests);

    const sumDataRequests = (data: any[]) => {
        for (let key in data[0]) {
            //@ts-ignore
            sumRequests[key] = 0;
        };

        data.reduce((acc: any, item: any) => {
            for (let key in item) {
                acc[key] += item[key];
            }
            return acc;
        }, sumRequests);
    };

    const {
        requests: activity_requests,
        blocks: activity_blocks,
        dangerous_blocks: activity_dangerous_blocks
    } = requestsData;

    useEffect(() => {
        //sum requests

        sumDataRequests(activityDataResult)
        setRequestsData({
            ...requestsData,
            requests: sumRequests.requests,
            blocks: sumRequests.blocks,
            dangerous_blocks: sumRequests.dangerous_blocks
        });

        //chart data create

        let activityChart = [
            ['Genre', 'Заблокированные запросы', 'Предотвращенные угрозы', 'Разрешенные запросы']
        ];

        activityDataResult.map((activity: any) => {
            activityChart.push([
                // period === 'Today' || period === 'Yesterday' ? activity.timestamp.split(' ')[1].replace(/,|.{3}$/g, '') : activity.timestamp, TODO timestamp replace bug
                period === 'Today' || period === 'Yesterday' ? activity.timestamp && activity.timestamp.split(' ')[1] : activity.timestamp,
                activity.blocks,
                activity.dangerous_blocks,
                activity.requests - activity.blocks,
            ]);
        });

        {error || activityDataResult.length === 0 ?
            setChartData(testChartData) :
            setChartData(activityChart)
        }
    }, [activityDataResult, error]);

    const statsCount = (count: number, title: string, type: string) => {
        return (
            <div className={`stats-activity-chart-data-${type}`}>
                <p>{checkCount(count)}</p>
                <span>{title}</span>
            </div>
        )
    };

    //chart data
    const options = {
        isStacked: true,
        bar: { groupWidth: '65%' },
        legend: {
            position: "none",
        },
        with: 768,
        height: 350,
        colors: ['#FFB700', '#E08D8D', '#219653'],
        backgroundColor: '#FFF',
        fontSize: 11,
        focusTarget: 'category',
        tooltip: {
            isHtml: true,
            textStyle: {
                color: '#333',
                fontSize: 14
            }
        }
    };

    return (
        <ContentBlock
            title={"Активность"}
            padding="0 24px"
            content={
                <>
                    <div className="stats-activity">
                        <div
                            className="stats-activity-chart"
                            style={{
                                opacity: loading || error || activity_requests === 0 ? 0.2 : 1,
                                pointerEvents: loading || error || activity_requests === 0 ? 'none' : 'auto'
                            }}
                        >

                            {error || activityDataResult.length === 0 ?
                                <div className="stats-activity-chart-data">
                                    {statsCount(loading ? 0 : 49322260, 'Общее количество неотфильтрованных запросов', 'all')}
                                    {statsCount(loading ? 0 : 3346818, 'Общее количество заблокированных запросов', 'blocked')}
                                    {statsCount(loading ? 0 : 445619, 'Общее количество предотвращенных угроз', 'cybersecurity')}
                                    {statsCount(loading ? 0 : 45975442, 'Разрешенные запросы', 'allowed')}
                                </div>
                                :
                                <div className="stats-activity-chart-data">
                                    {statsCount(loading ? 0 : activity_requests, 'Общее количество неотфильтрованных запросов', 'all')}
                                    {statsCount(loading ? 0 : activity_blocks - activity_dangerous_blocks, 'Общее количество заблокированных запросов', 'blocked')}
                                    {statsCount(loading ? 0 : activity_dangerous_blocks, 'Общее количество предотвращенных угроз', 'cybersecurity')}
                                    {statsCount(loading ? 0 : activity_requests - activity_blocks, 'Разрешенные запросы', 'allowed')}
                                </div>
                            }
                            <Chart
                                chartType="ColumnChart"
                                width="100%"
                                data={chartData}
                                options={options}
                            />
                        </div>
                        <UpdateStatsButton
                            updateStats={getActivityStats}
                            lastUpdate={lastUpdate}
                            disabledTimeout={5000}
                        />

                        {loading &&
                            <Loader
                                text='Пожалуйста, подождите...'
                            />
                        }

                        {error &&
                            <FetchError
                                text={error}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getActivityStats}
                            />
                        }

                        {!error && activity_requests === 0 &&
                            <FetchError
                                text={loading ? "" : "У вас еще нет статистики"}
                                isButton={true}
                                isButtonDisabled={loading ? true : false}
                                isIcon={false}
                                clickHeandler={getActivityStats}
                            />
                        }
                    </div>
                    <InfoBlockAccordion
                        title="Общая информация об активности всех ваших пользователей за определенный период."
                        margin="24px 0 0"
                        is_content={false}
                    />
                </>
            }
        />
    )
};

export default Activity;
